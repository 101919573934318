@font-face {
  font-family: "Claudia";
  src: url("claudia-script.ttf") format("truetype");
}
@font-face {
  font-family: "Futura Book";
  src: url("Futura-Book-Regular.otf") format("opentype");
}
img#instructor-photo {
  width: 400px;
}
a.tiffany {
  color: #96f1e0;
  text-shadow: 1px 1px grey;
}
.navbar,
.navbar-expand-lg,
.avbar-light,
.bg-light,
.footer {
  background-color: rgb(150, 241, 224, 0.25) !important;
}
.App {
  text-align: center;
  font-family: "Futura Book", sans-serif;
}
#contact-content {
  font-family: "Futura Book", sans-serif;
}
input,
textarea,
button,
.overlay,
.footer {
  font-family: "Futura Book", sans-serif;
}
#checkout-instagram {
  text-align: center;
  margin: auto;
  margin-bottom: 25px;
  font-size: 1.4em;
}
.btn-primary {
  color: #fff;
  background-color: black;
  border-color: black;
}
.form-text {
  font-size: 1.2em;
}
#charity-support-footer-mobile {
  margin: auto;
  margin-top: 20px;
  width: 250px;
}
#pop {
  height: 120px;
  float: right;
}
#popl {
  display: inline-block;
  float: left;
  width: 150px;
  padding: 0;
  padding-right: 25px;
  font-family: "Futura Book", sans-serif;
  font-size: 0.8em;
}
#contact-content {
  font-family: "Futura Book", sans-serif;
}
#popl a {
  text-decoration: none;
}
nav {
  /*background: #425447;*/
  -webkit-box-shadow: 0 8px 6px -6px LightGrey;
  -moz-box-shadow: 0 8px 6px -6px LightGrey;
  box-shadow: 0 10px 8px -8px LightGrey;
  font-family: "Futura Book", sans-serif;
}
#footer-top {
  width: 100%;
  height: 50px;
}
.navbar-nav {
  font-size: 1.5em;
}
h1 {
  font-size: 3.5em;
  margin-top: 50px;
  margin-bottom: 25px;
}
#homepage-content,
#contact-content {
  margin: auto;
  margin-top: 50px;
  margin-bottom: 50px;
  width: 750px;
  font-size: 1.2em;
}
.instruction-content {
  font-size: 1.2em;
  margin-bottom: 50px;
  margin-top: 50px;
  margin-left: 100px;
  margin-right: 100px;
}
.instruction-content p {
  margin-top: 70px;
  text-align: center;
}
.instruction {
  display: inline-block;
  width: 45%;
  margin: auto;
}
#instruction-photo-note {
  margin: auto;
  width: 50%;
  text-align: center;
  margin-bottom: 25px;
  margin-top: 25px;
  display: block;
}
#overflow {
  overflow: auto;
  margin-top: 25px;
}
.student-work {
  display: inline-block;
  padding: 20px;
}
.student-work img {
  max-width: 80%;
}
@media (max-width: 500px) {
  #header-logo {
    height: 40px;
  }
}
@media (max-width: 750px) {
  h1 {
    font-size: 2em;
  }
  #homepage-content,
  .instruction-content,
  #checkout-instagram {
    font-size: 1em;
  }
  .instruction {
    display: block;
    width: 100%;
    margin: auto;
  }
  .instruction-content img {
    max-width: 250px;
    max-width: 90%;
    margin-top: 20px;
  }
  .instruction-content p {
    margin-top: 40px;
  }
  .instruction-content {
    font-size: 1em;
    margin: 20px;
    margin-top: 30px;
    margin-bottom: 30px;
  }
  #instruction-photo-note {
    width: 75%;
  }
}
body {
  /*    background: url('img/cardboard-transparent.png');*/
  background-size: 100% 100%;
}
hr.rounded {
  border-top: 8px solid #bbb;
  border-radius: 5px;
}
.artSample {
  position: relative;
}
.artPiece {
  display: block;
  width: 100%;
  height: auto;
  transition: 0.5s ease;
}
.overlay {
  font-weight: bold;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  font-size: 1.3em;
  transition: 0.5s ease;
}
.artSample:hover .overlay {
  opacity: 1;
}
.artSample:hover .artPiece {
  opacity: 0.5;
}
.mr-auto,
.mx-auto {
  margin-left: auto !important;
}
form {
  width: 600px;
  max-width: 70%;
  margin: auto;
}

form input,
form textarea {
  margin-bottom: 5px;
  margin-top: 25px;
}
form button {
  margin-bottom: 30px;
}

p {
  text-align: left;
}
.left {
  float: left;
}
.right {
  float: right;
}
h2 {
  margin-block-start: 0.2em;
  margin-block-end: 0.2em;
}
h3 {
  margin-block-start: 0.2em;
  margin-block-end: 0.5em;
}
hr {
  margin-left: 20px;
  margin-right: 20px;
}
.footer {
  height: 250px;
  display: block;
  clear: both;
  padding: 50px;
  font-size: 20px;
}
.footer span {
  padding-left: 25px;
  padding-right: 25px;
}
.footer img {
  height: 40px;
}
@media (max-width: 650px) {
  .footer {
    padding-left: 10px;
    padding-right: 10px;
  }
  .footer img {
    height: 30px;
  }
  .footer span {
    padding-left: 5px;
    padding-right: 5px;
  }
}
@media (min-width: 0px) {
  .lexi {
    height: 200px;
    padding-left: 5px;
    padding-right: 5px;
  }
  #long-lexi {
    height: 315px;
  }
  img.artPiece {
    display: inline;
    margin: 20px;
    max-width: 280px;
  }
  #charity-support-footer-desktop {
    display: none;
  }
  #charity-support-footer-mobile {
    display: inline-block;
  }
  #homepage-content,
  #contact-content {
    width: 300px;
    font-size: 1.2em;
  }
}
@media (min-width: 650px) {
  .lexi {
    height: 400px;
    padding-left: 25px;
    padding-right: 25px;
  }
  #long-lexi {
    height: 450px;
  }
  #homepage-content,
  #contact-content {
    width: 500px;
    font-size: 1.2em;
  }
  img.artPiece {
    display: inline;
    margin: 20px;
    max-width: 400px;
  }
  #charity-support-footer-mobile {
    display: none;
  }
  #charity-support-footer-desktop {
    display: inline-block;
  }
  .footer span {
    padding-left: 5px;
    padding-right: 5px;
  }
}
@media (min-width: 900px) {
  .lexi {
    height: 500px;
  }
  #long-lexi {
    height: 700px;
  }
  #homepage-content,
  #contact-content {
    width: 750px;
    font-size: 1.2em;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 2rem;
    padding-left: 2rem;
  }
  #header-logo {
    padding-left: 80px;
  }
}
@media (min-width: 1200px) {
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 4rem;
    padding-left: 4rem;
  }
}
/* START loading icon css taken from https://loading.io/css/ */
.lds-circle {
  display: inline-block;
  transform: translateZ(1px);
}

.lds-circle > div {
  display: inline-block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  background: #000;
  animation: lds-circle 2.4s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

@keyframes lds-circle {
  0%,
  100% {
    animation-timing-function: cubic-bezier(0.5, 0, 1, 0.5);
  }
  0% {
    transform: rotateY(0deg);
  }
  50% {
    transform: rotateY(1800deg);
    animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1);
  }
  100% {
    transform: rotateY(3600deg);
  }
}
/* END loading icon css taken from https://loading.io/css/ */
